import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const fontMain = "'AvtGarde', sans-serif"

const white = '#fff'
const black = '#000'
const primaryMain = '#AB93C5'
//const primaryDark = '#f07a95'
// const primaryLight = '#fef6f9'
const secondaryMain = '#B5DCCD' //#B5DCCD = vert (ancienne couleur const secondaryMain = '#36b74f')
const greyLight = '#F0F0F0'
const greyText = '#ACACAC'
const grey = '#C3C3C3'
const greyAlt = '#E2E2E2'
const imp = '!important'
const headerHeightMobile = '50px'

//nouvelle variable Paul 8 avril 2024
const green = '#B5DCCD'
const parme = '#AB93C5'

const hpTitle = {
  ...heading('25px', '18px', 600, 600, fontMain, fontMain, true),
  letterSpacing: `.64px ${imp}`,
  color: `${secondaryMain} ${imp}`,
  textAlign: `center ${imp}`,
}

const btn2ndHand = {
  border: `2px solid ${green} ${imp}`,
  borderRadius: `8px ${imp}`,
  background: `none ${imp}`,
  padding: `4px 36px ${imp}`,
  color: `${black} ${imp}`,
  fontSize: `12px ${imp}`,
  lineHeight: `1.6 ${imp}`,
  '&:hover, &:focus': {
    background: `${green} ${imp}`,
    color: `${white} ${imp}`,
  },
  '&[disabled]': {
    borderColor: `rgba(54, 183, 79, 0.2) ${imp}`,
    background: `none ${imp}`,
    color: `${grey} ${imp}`,
  },
}

const btn2ndHandOutlined = {
  border: `1px solid ${black} ${imp}`,
  borderRadius: `13px ${imp}`,
  background: `none ${imp}`,
  padding: `6px 12px 5px ${imp}`,
  color: `${black} ${imp}`,
  '&:hover, &:focus': {
    background: `${black} ${imp}`,
    color: `${white} ${imp}`,
  },
}

const title10 = {
  fontSize: `10px ${imp}`,
  letterSpacing: `1px ${imp}`,
  lineHeight: `1.5 ${imp}`,
  fontWeight: `600 ${imp}`,
}

const title11 = {
  textTransform: `uppercase ${imp}`,
  fontWeight: `600 ${imp}`,
  fontSize: `11px ${imp}`,
  letterSpacing: `1.1px ${imp}`,
}

const title12 = {
  fontSize: `12px${imp}`,
  letterSpacing: `1.2px${imp}`,
}

const title14 = {
  fontSize: `14px ${imp}`,
  letterSpacing: `1.4px ${imp}`,
  textTransform: `uppercase ${imp}`,
  fontWeight: `600 ${imp}`,
}

const title18 = {
  fontSize: `18px ${imp}`,
  letterSpacing: `.64px ${imp}`,
  fontWeight: `600 ${imp}`,
}

const title32 = {
  fontSize: `32px ${imp}`,
  letterSpacing: `3.3px ${imp}`,
  textTransform: `uppercase ${imp}`,
  fontWeight: `600 ${imp}`,
  color: `${secondaryMain} ${imp}`,
}

const titleWithLogo = {
  color: secondaryMain,
  fontSize: 22,
  letterSpacing: '1.9px',
  textTransform: 'uppercase',
  '&:after': {
    content: '""',
    display: 'inline-block',
    verticalAlign: '-20%',
    background: 'url("/images/maje/icon_maje_arrow_blk.svg") no-repeat 50% 50%',
    backgroundSize: 'contain',
    width: 43,
    height: 40,
    marginLeft: 25,
  },
}
const listStyle = {
  listStyle: 'disc inside',
  paddingLeft: 0,
  lineHeight: 20 / 12,
  textAlign: `inherit ${imp}`,
  marginBottom: `42px ${imp}`,
  '& li': {
    marginBottom: `15px ${imp}`,
  },
  '& li::marker': {
    content: '"• "',
    color: `${secondaryMain}`,
    marginRight: '1ch',
  },
  '& strong': {
    fontWeight: `600 ${imp}`,
  },
}

const customSelect = {
  margin: `0 ${imp}`,
  fontSize: `11px ${imp}`,
  fontWeight: `500 ${imp}`,
  fontFamily: `${fontMain} ${imp}`,
  border: `1px solid ${black} ${imp}`,
  appearance: 'none',
  padding: ` 11.5px 40px 11.5px 15px ${imp}`,
  background: `${white} url("./images/maje/select_arrow.svg") no-repeat 100% 50% ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const customCheckRadioCommon = {
  '& .MuiRadio-root, & .MuiCheckbox-root': {
    padding: `0 ${imp}`,
    margin: `0 5px 0 0 ${imp}`,
    width: `19px ${imp}`,
    '& .MuiSvgIcon-root': {
      width: `19px ${imp}`,
      height: `19px ${imp}`,
      visibility: `hidden ${imp}`,
    },
  },
}

const customRadio = {
  '& .MuiRadio-root': {
    background: 'url("/images/maje/radio_off.svg") no-repeat',
    backgroundSize: '19px 19px',
    backgroundPosition: '0 0',
    '&.Mui-checked': {
      backgroundImage: 'url("/images/maje/radio_on.svg")',
    },
  },
}

const customCheckBox = {
  '& .MuiCheckbox-root': {
    borderRadius: `0 ${imp}`,
    backgroundImage: 'url("/images/maje/checkbox_off_v2.svg")',
    backgroundSize: '15px 15px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    '&.Mui-checked': {
      backgroundImage: 'url("/images/maje/checkbox_on_v2.svg")',
    },
  },
}

const iconAccountWhite = {
  '& .MuiSvgIcon-root': {
    background: `url("/images/maje/icon_account_wht.svg") no-repeat 0 50% ${imp}`,
    backgroundSize: `contain ${imp}`,
  },
  '& .MuiSvgIcon-root, & .MuiButton-label': {
    height: `20px ${imp}`,
  },
}

const iconFaqWhite = {
  '& .MuiSvgIcon-root': {
    background: `url("/images/maje/icon_faq_wht.svg") no-repeat 0 50% ${imp}`,
    backgroundSize: `contain ${imp}`,
  },
  '& .MuiSvgIcon-root, & .MuiButton-label': {
    width: `24px ${imp}`,
  },
}

// const iconCartWhite = {
//   background: `url("/images/maje/cart_icon_wht.svg") no-repeat 0 50% ${imp}`,
//   backgroundSize: `contain ${imp}`,
//   '& .MuiButton-label': {
//     height: `19px ${imp}`,
//     '& > img, & .injected-svg': {
//       display: `none ${imp}`,
//       visibility: `hidden ${imp}`,
//     },
//   },
// }

const iconMenuBurgerWhite = {
  marginLeft: `16px ${imp}`,
  width: `24px ${imp}`,
  color: `${white} ${imp}`,
  background: `url("/images/maje/burger_wht.svg") no-repeat 50% 50% ${imp}`,
  backgroundSize: `contain ${imp}`,
  '& path': {
    display: `none ${imp}`,
    visibility: `hidden ${imp}`,
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'AvtGarde',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: fontMain,
    h1: {
      fontSize: 33,
      lineHeight: 1.15,
      fontWeight: 600,
      letterSpacing: '4.9px',
    },
    h2: {
      fontSize: 28,
      lineHeight: 1.15,
      fontWeight: 700,
      letterSpacing: '2.8px',
    },
    h3: {
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 600,
      letterSpacing: '1.4px',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 600,
      letterSpacing: '1.4px',
      textTransform: 'uppercase',
    },
    h5: {
      fontSize: 12,
      letterSpacing: '1.2px',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    h6: {
      fontSize: 11,
      letterSpacing: '1.1px',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    body1: {
      fontSize: 12,
      lineHeight: 1.67,
      fontWeight: 500,
    },
    body2: {
      fontSize: 12,
      lineHeight: 1.67,
      fontWeight: 500,
    },
    caption: {
      fontSize: 10,
      letterSpacing: '0.6px',
      lineHeight: 1.9,
      fontWeight: 500,
    },
    button: {
      fontSize: 12,
      letterSpacing: '.16px',
      lineHeight: 1.2,
      fontWeight: 600,
    },
  },
  palette: {
    common: {
      black: black,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: secondaryMain,
      dark: secondaryMain,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      contrastText: white,
    },
    success: {
      main: '#000',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: '#000',
    primaryTransparent: {
      main: black,
      light: 'transparent',
    },
    secondaryTransparent: {
      main: black,
      light: 'transparent',
    },
    ternary: {
      main: primaryMain,
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 77,
    headerHeightTransparent: 77,
    logoWidth: 140,
    logoHeight: 37,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
    bannerMargin: 23,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyText,
    greyText: greyText,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          background: `${white} ${imp}`,
        },
        '.select': {
          ...customSelect,
        },
        '.takeback__input': {
          color: `${black} ${imp}`,
          fontSize: `11px ${imp}`,
          fontWeight: `500 ${imp}`,
          lineHeight: `1.64 ${imp}`,
          letterSpacing: `0.73px ${imp}`,
          '&::placeholder': {
            color: `${black} ${imp}`,
            opacity: `1 ${imp}`,
          },
          height: 'fit-content',
        },
        '.takeback__input_form': {
          border: `1px solid ${black} ${imp}`,
          background: `${white} ${imp}`,
          padding: `10px 15px 8px ${imp}`,
          borderRadius: '0',
          height: 'fit-content',
          flexDirection: 'row-reverse',
          '& .aa-InputWrapperPrefix': {
            height: '20px',
            '& svg': {
              color: '#000',
            },
          },
          '& .aa-InputWrapperSuffix': {
            display: 'none',
          },
          '& .aa-SubmitButton': {
            width: '20px',
            padding: 0,
          },
          '&:focus-within': {
            boxShadow: 'none',
            outline: 'none',
          },
        },
        '.input, .geosuggest__input': {
          border: `1px solid ${black} ${imp}`,
          borderRadius: `0 ${imp}`,
          background: `${white} ${imp}`,
          color: `${black} ${imp}`,
          padding: `10px 15px 8px ${imp}`,
          fontSize: `11px ${imp}`,
          fontWeight: `500 ${imp}`,
          lineHeight: `1.64 ${imp}`,
          letterSpacing: `0.73px ${imp}`,
          '&::placeholder': {
            color: `${black} ${imp}`,
            opacity: `1 ${imp}`,
          },
          '&.PhoneInput': {
            padding: `3px 8px ${imp}`,
            '&>.PhoneInputInput': {
              fontSize: `11px ${imp}`,
              fontWeight: `500 ${imp}`,
              '&::placeholder': {
                color: `${black} ${imp}`,
                opacity: `1 ${imp}`,
              },
            },
          },
          '&:focus': {
            outline: `none ${imp}`,
          },
          '&[data-com-onepassword-filled="light"], &[data-com-onepassword-filled="dark"], &:autofill, &:-webkit-autofill':
            {
              boxShadow: `0 0 0 50px #fff inset ${imp}`,
              '&:focus': {
                boxShadow: `0 0 0 50px #fff inset ${imp}`,
              },
            },
        },
        '.radio, .checkbox': {
          ...customCheckRadioCommon,
        },
        '.radio': {
          ...customRadio,
        },
        '.checkbox': {
          ...customCheckBox,
        },
        '.btn--logout.MuiButton-containedSecondary': {
          ...title10,
          border: `0 ${imp}`,
          padding: `0 ${imp}`,
          background: `none ${imp}`,
          color: `${black} ${imp}`,
          textTransform: `inherit ${imp}` as any,
          display: `inline-flex ${imp}`,
          '&:hover, &:focus': {
            textDecoration: `none ${imp}`,
            background: `none ${imp}`,
            color: `${black} ${imp}`,
          },
        },
        '.fade': {
          opacity: `1 ${imp}`,
        },
        '.react-datepicker': {
          borderRadius: `0 ${imp}`,
        },
        '.react-datepicker__navigation::before, .react-datepicker__navigation::after':
          {
            backgroundColor: `${black} ${imp}`,
          },
        '.react-datepicker__day--in-range, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
          {
            color: `${black} ${imp}`,
          },
        '.react-datepicker-popper>div>div>div+div': {
          backgroundColor: `${secondaryMain}${imp}`,
          '&>div': {
            color: `${white} ${imp}`,
          },
        },
        '.react-datepicker-wrapper': {
          '@media (min-width: 768px)': {
            width: `152px ${imp}`,
            textAlign: 'center',
          },
          '@media (min-width: 1366px)': {
            width: `190px ${imp}`,
          },
        },
        '#swiper-products__prev, #swiper-products__next': {
          '&.swiper-button-disabled': {
            display: `none ${imp}`,
          },
          '&>.MuiButton-label': {
            '&:before': {
              content: '""',
              background:
                'url("./images/maje/icon_arrow_left.svg") no-repeat 50% 50%',
              width: 24,
              height: 24,
            },
            '& svg': {
              display: 'none',
            },
          },
          '&:hover, &:focus': {
            background: `none ${imp}`,
          },
          '@media (max-width: 1024px)': {
            display: `none ${imp}`,
          },
        },
        '#swiper-products__next': {
          '&>.MuiButton-label': {
            '&:before': {
              background:
                'url("./images/maje/icon_arrow_right.svg") no-repeat 50% 50%',
            },
          },
        },
        '.banner': {
          height: `auto ${imp}`,
          minHeight: `34px ${imp}`,
          background: `${black} ${imp}`,
          padding: `12px 16px ${imp}`,
          '& .MuiTypography-root': {
            color: `${white}${imp}`,
            ...title11,
          },
          '& .paragraph-class': {
            margin: '0',
            textTransform: `none ${imp}`,
            lineHeight: `1.4 ${imp}`,
            letterSpacing: `1.9px ${imp}`,
            fontWeight: `600 ${imp}`,
          },
        },
        '.header': {
          '&--top': {
            zIndex: 1000,
          },
          '&__layout': {
            '@media (max-width: 1024px)': {
              height: `${headerHeightMobile} ${imp}`,
            },
          },
          '&__right': {
            border: `0 ${imp}`,
          },
          '&__logo': {
            height: `23px ${imp}`,
            '@media (min-width: 480px)': {
              height: `30px ${imp}`,
            },
          },
          '&__icons': {
            borderLeft: `0 ${imp}`,
            '@media (min-width: 1024px)': {
              flexDirection: `row-reverse ${imp}`,
            },
          },
          '&__burger': {
            marginLeft: `16px ${imp}`,
            width: `24px ${imp}`,
            color: `${black} ${imp}`,
            background: `url("/images/maje/burger.svg") no-repeat 50% 50% ${imp}`,
            backgroundSize: `contain ${imp}`,
            '& path': {
              display: `none ${imp}`,
              visibility: `hidden ${imp}`,
            },
          },
          '.header--transparent &': {
            '&__burger': {
              ...iconMenuBurgerWhite,
            },
          },
          '&__cta, &__lang': {
            marginLeft: `.8rem ${imp}`,
            '@media (min-width: 1366px)': {
              marginLeft: `1.55rem ${imp}`,
            },
          },
          '&__cta, &__langbtn, &__extraLink': {
            ...title11,
          },
          '&__langbtn': {
            '&[aria-current=true]': {
              fontWeight: 500,
            },
          },
          '&__extraLink': {
            '& .MuiTypography-srOnly': {
              position: 'static',
              overflow: 'inherit',
              width: 'inherit',
              height: 'inherit',
            },
          },
          '&__menu': {
            display: 'flex',
            flexDirection: 'row-reverse',
            '@media (max-width: 1024px)': {},
          },
          '&__cta': {
            '@media (min-width: 1024px)': {
              fontSize: `0 ${imp}`,
            },
            '& .MuiSvgIcon-root, & .MuiButton-label': {
              width: `20px ${imp}`,
              height: `20px ${imp}`,
            },
            '& .MuiSvgIcon-root': {
              marginRight: `0 ${imp}`,
              '&>path': {
                display: `none ${imp}`,
                visibility: `hidden ${imp}`,
              },
            },
            '.header--transparent &': {
              '&--account': {
                ...iconAccountWhite,
              },
              '&--faq': {
                ...iconFaqWhite,
              },
            },
            '&--takeback': {
              '& .MuiSvgIcon-root': {
                background: `url("/images/maje/recycle-light.svg") no-repeat 50% 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
              },
              '.header--transparent &': {
                // background: `url("/images/greenweez/account_white.svg") no-repeat 0 50% ${imp}`,
              },
            },
            '&--account': {
              '& .MuiSvgIcon-root': {
                background: `url("/images/maje/icon_account_blk.svg") no-repeat 50% 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
              },
              '& .MuiSvgIcon-root, & .MuiButton-label': {
                height: `20px ${imp}`,
              },
            },
            '&--faq': {
              '& .MuiSvgIcon-root': {
                background: `url("/images/maje/icon_faq_blk.png") no-repeat 0 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
              },
              '& .MuiSvgIcon-root, & .MuiButton-label': {
                width: `24px ${imp}`,
              },
              // '.header--transparent &': {
              //   background: `url("/images/maje/icon_faq_blk.svg") no-repeat 0 50% ${imp}`,
              // },
            },
          },
        },

        '.header--top--page': {
          '&__vendre, &__sell': {
            '& .MuiContainer-root': {
              '& .header__layout': {
                '& .header__center': {
                  '& .menu': {
                    '&__link': {
                      color: `${white} ${imp}`,
                    },
                  },
                },
                '& .header__right': {
                  '& .header__cta': {
                    '&--account': { ...iconAccountWhite },
                    '&--faq': { ...iconFaqWhite },
                  },
                  '& #menu-toggler': {
                    color: `${white} ${imp}`,
                    //...iconCartWhite,
                  },
                  '& .header__lang': {
                    color: `${white} ${imp}`,
                    '& .header__langbtn': {
                      color: `${white} ${imp}`,
                    },
                  },
                  '& .header__burger': {
                    ...iconMenuBurgerWhite,
                  },
                  '& .search__button__icon, .search__button__icon__only': {
                    color: `${white} ${imp}`,
                    borderColor: `${white} ${imp}`,
                  },
                },
              },
            },
          },
        },

        '.menu': {
          '.header--transparent &': {},
          '&__link': {
            textTransform: `uppercase ${imp}`,
            letterSpacing: `1px ${imp}`,
            '.header--transparent &': {},
            '&[target="_blank"]': {},
            '@media (min-width: 1024px)': {
              '&:hover, &:focus': {
                textDecoration: 'underline',
              },
            },
          },
          '&__list': {
            '&--primary': {
              '@media (min-width: 1024px)': {
                gap: `24px ${imp}`,
                whiteSpace: `nowrap ${imp}`,
                '& .menu__item:after': {
                  display: 'none',
                },
              },
              '@media (min-width: 1366px)': {
                gap: `45px ${imp}`,
              },
            },
            '&--mobile': {
              '&>li': {
                '&:last-child>a': {
                  fontWeight: `700 ${imp}`,
                },
              },
            },
          },
          '&__item': {
            '&:last-child>a': {
              fontWeight: `700 ${imp}`,
            },
          },
          '&--mobile': {
            border: `0 ${imp}`,
          },
          '&-mobile': {
            '&__header': {
              height: `${headerHeightMobile} ${imp}`,
              borderBottom: `0 ${imp}`,
            },
            '&__close': {
              fontSize: `0 ${imp}`,
            },
            '&__back': {
              top: `${headerHeightMobile} ${imp}`,
              background: `transparent ${imp}`,
            },
            '&__content': {
              marginTop: `${headerHeightMobile} ${imp}`,
              background: `${white} ${imp}`,
            },
            '&__item': {
              '&--primary': {
                fontSize: `15px ${imp}`,
                textTransform: `uppercase ${imp}`,
                fontWeight: `400 ${imp}`,
                borderBottom: `0 ${imp}`,
                paddingTop: `17.5px ${imp}`,
                paddingBottom: `17.5px ${imp}`,
              },
            },
            '&__footer': {
              background: `${white} ${imp}`,
              padding: `40px 16px ${imp}`,
              display: `flex ${imp}`,
              flexDirection: `column ${imp}`,
              gap: `23px ${imp}`,
            },
            '&__cta': {
              color: `${black} ${imp}`,
              background: `${white} ${imp}`,
              padding: `0 ${imp}`,
              fontWeight: `400 ${imp}`,
              fontSize: `12px ${imp}`,
              textTransform: `none ${imp}`,
              '& .MuiSvgIcon-root': {
                display: `none ${imp}`,
              },
            },
          },
        },
        '.main': {
          '&--shop, &--pack': {
            // zIndex: 1101, // VS header
            // position: 'relative',
          },
          '&--shop': {
            marginTop: `110px ${imp}`,
            '@media (min-width: 1024px)': {
              marginTop: `125px ${imp}`,
            },
          },
        },
        '.hero__home': {
          background: `#f7f7f7 ${imp}`,
          '@media (max-width: 1024px) and (max-height: 667px)': {
            minHeight: `590px ${imp}`,
          },
          '&>video': {
            backgroundColor: `#f7f7f7 ${imp}`,
            height: '100%',
            '@media (max-width: 1024px)': {
              //top: `${headerHeightMobile} ${imp}`,
            },
            //fix weird border on safari iOS
            '@media (max-width: 1024px) and (max-height: 667px)': {
              //height: 452, // === header Mobile height and and display section-menu above the fold
            },
          },
          '& .wrapper': {
            paddingTop: `${headerHeightMobile} ${imp}`,
            minHeight: 'calc(100vh - 195px)', // Display section-menu above the fold
            '@media only screen and (max-width: 1024px) and (max-height: 667px)':
              {
                minHeight: `calc(600px - ${headerHeightMobile})`, // header height minus banner and display section-menu above the fold
              },
          },
          '& .layout': {
            '@media (max-width: 1024px)': {
              paddingTop: `25px ${imp}`,
            },
          },
          '&.left': {
            '& .MuiGrid-root.layout > .MuiGrid-grid-md-6': {
              '@media (min-width: 768px)': {
                maxWidth: '66.666667%',
                flexBasis: '66.666667%',
                '&>div': {
                  paddingLeft: 110,
                  marginTop: -32,
                },
              },
            },
          },
          '& .tagline': {
            fontSize: `15px ${imp}`,
            letterSpacing: '2.2px',
            '& p': {
              margin: 0,
            },
            '@media (max-width: 1024px)': {
              marginBottom: `0 ${imp}`,
            },
          },
          '& .title': {
            fontWeight: `500 ${imp}`,
            fontSize: `46px ${imp}`,
            letterSpacing: `.61px ${imp}`,
            marginBottom: `35px ${imp}`,
            '& strong': {
              fontWeight: `500 ${imp}`,
            },
            '@media (min-width: 1366px)': {
              fontSize: `110px ${imp}`,
            },
          },
          '& .daterange': {
            border: `0 ${imp}`,
            '@media (max-width: 767px)': {
              paddingLeft: `0 ${imp}`,
              paddingRight: `0 ${imp}`,
            },
          },
          '& .date_form': {
            '@media (max-width: 1024px)': {
              marginTop: `0 ${imp}`,
              paddingTop: `10px ${imp}`,
              '& [data-testid=submitDates]': {
                width: 'auto',
              },
            },
          },
          '& .ctas': {
            position: `absolute ${imp}`,
            left: `0 ${imp}`,
            right: `0 ${imp}`,
            bottom: `35px ${imp}`,
            '& .MuiButton-root': {
              color: `${white} ${imp}`,
              background: `${parme} ${imp}`,
              borderColor: `${parme} ${imp}`,
              '&:hover, &:focus': {
                background: `${parme} ${imp}`,
              },
            },
            '@media (max-width: 1024px)': {
              '&>a': {
                margin: `14px ${imp}`,
              },
              '& .MuiButton-root': {
                minWidth: `170px ${imp}`,
              },
            },
            '@media (min-width: 1366px)': {
              bottom: `25px ${imp}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: `98px ${imp}`,
            },
            '@media (min-width: 1920px)': {
              //bottom: `70px ${imp}`,
            },
            '@media (max-width: 1024px) and (max-height: 667px)': {
              bottom: `10px ${imp}`,
            },
          },
        },
        '.sizes': {
          alignItems: `baseline ${imp}`,
          '&>.MuiTypography-h6': {
            ...title12,
            fontWeight: `500 ${imp}`,
          },
          '&__btn': {
            border: `1px solid ${green} ${imp}`,
            minWidth: `24px ${imp}`,
            height: `24px ${imp}`,
            fontSize: `12px ${imp}`,
            fontWeight: `500 ${imp}`,
            padding: `0 8px ${imp}`,
            lineHeight: `1 ${imp}`,
            background: `#fff ${imp}`,
            borderRadius: `8px ${imp}`,
            '&[disabled]': {
              borderColor: `#bdbdbd ${imp}`,
              color: `#bdbdbd ${imp}`,
            },
            '&:hover, &:focus, &.selected': {
              background: `${green} ${imp}`,
              borderColor: `${green} ${imp}`,
              color: `${white} ${imp}`,
            },
            '&.selected': {
              borderWidth: `1.5px ${imp}`,
              fontWeight: `600 ${imp}`,
            },
          },
          '&__chip': {
            top: `-16px${imp}`,
            right: `-20px${imp}`,
          },
          '&-add__btn': {
            color: `${white} ${imp}`,
            background: `${black} ${imp}`,
            borderColor: `${black} ${imp}`,
            '&:hover, &:focus': {
              color: `${white} ${imp}`,
              background: `${black} ${imp}`,
            },
          },
        },
        '.size-card': {
          '&__title': {
            ...title14,
            fontWeight: `700 ${imp}`,
          },
          '&__top, &__bottom': {
            padding: 16,
          },
        },
        '.quantitySelector': {
          '&__total': {
            borderRadius: `0 ${imp}`,
            width: `32px ${imp}`,
            height: `32px ${imp}`,
            '&> span': {
              top: 1,
              position: 'relative',
            },
            '&--small': {
              width: `22px ${imp}`,
              height: `22px ${imp}`,
            },
          },
          '&__btn>span': {
            top: 2,
          },
        },
        '.size-modal': {
          '&--pack': {
            '& .MuiPaper-root': {
              '@media (min-width: 1024px)': {
                maxWidth: 1085,
                width: '100%',
              },
            },
          },
          '&--product': {
            '& .MuiPaper-root': {
              '@media (min-width: 1024px)': {
                maxWidth: 660,
                width: '100%',
                '@media (max-height: 1024px)': {},
              },
            },
          },
          '& .MuiDivider-root, &__head, &__foot': {
            background: `none ${imp}`,
          },
          '&__head, &__foot': {
            '@media (min-width: 1366px)': {
              marginRight: 36,
              marginLeft: 36,
            },
          },
          '&__title': {
            '&.MuiTypography-root': {
              ...title14,
              '@media (min-width: 1366px)': {
                marginTop: 45,
              },
            },
          },
          '&__close': {
            '&.MuiSvgIcon-root': {
              backgroundColor: white,
              color: black,
              '@media (min-width: 1366px)': {
                marginTop: 40,
              },
            },
          },
          '&__content': {
            border: `0 ${imp}`,
          },
        },
        '.shop-filters': {
          color: `${black} ${imp}`,
          borderColor: `transparent ${imp}`,
          '&:hover, &:focus': {
            color: `${black} ${imp}`,
            borderColor: `black ${imp}`,
            background: `transparent ${imp}`,
          },
        },
        '.shop-menu': {
          '&__cta': {
            '&-link': {
              ...btn2ndHandOutlined,
            },
          },
          '&--products': {
            '@media (max-width: 1024px)': {
              flexDirection: `column ${imp}`,
              '& .shop-menu__cta': {
                textAlign: `center ${imp}`,
                marginBottom: `0 ${imp}`,
                '&-link': {
                  fontSize: `11px ${imp}`,
                },
              },
            },
          },
          '&--pack': {
            '@media (max-width: 1024px)': {
              flexDirection: `row ${imp}`,
              '& .shop-menu__cta': {
                marginBottom: `0 ${imp}`,
              },
            },
          },
        },
        '.pack': {
          '&__title, &__products-title, &-product-modal__title': {
            '& .MuiTypography-root': {
              ...title12,
              lineHeight: 1.6,
              fontWeight: 600,
              textTransform: 'uppercase',
            },
          },
          '&-product': {
            '&__foot': {
              paddingLeft: `0 ${imp}`,
              paddingRight: `0 ${imp}`,
            },
            '&__title': {
              ...title12,
              fontWeight: `400 ${imp}`,
              textTransform: `none  ${imp}`,
              minHeight: `0 ${imp}`,
            },
            '&-modal': {
              '&__title': {
                '&.MuiTypography-root': {
                  ...title12,
                  lineHeight: 1.6,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                },
              },
              '&__desc': {
                ...title11,
                fontWeight: `500 ${imp}`,
                textTransform: `inherit ${imp}`,
                lineHeight: `1.7 ${imp}`,
                '& *': {
                  marginTop: `0 ${imp}`,
                  marginBottom: `30px ${imp}`,
                },
                '& ul, & ol': {
                  paddingLeft: '1em',
                  '& li': {
                    marginBottom: `0 ${imp}`,
                  },
                },
              },
            },
          },
          '&__by': {
            paddingLeft: '1ch',
            paddingRight: '1ch',
          },
          '&__price': {
            marginTop: `0 ${imp}`,
            fontSize: `10px ${imp}`,
            fontWeight: `700 ${imp}`,
            color: `${black} ${imp}`,
            letterSpacing: `1px${imp}`,
            '&.MuiTypography-root': {
              fontWeight: 500,
            },
          },
          '&__grid': {
            '& > .MuiGrid-item:first-child': {
              order: 999, // keep first pack at the end of the list
            },
          },
        },
        '.packFashion': {
          '& .sliderNav, & .glider-next, & .glider-prev': {
            background: `none ${imp}`,
          },
        },
        '.product, .productCard': {
          '&__by': {
            paddingLeft: '1ch',
            paddingRight: '1ch',
          },
        },
        '.productCard': {
          borderRadius: `0 ${imp}`,
          '&__price': {
            marginTop: `0 ${imp}`,
            fontSize: `10px ${imp}`,
            fontWeight: `700 ${imp}`,
            color: `${black} ${imp}`,
            letterSpacing: `1px ${imp}`,
          },
          '&__title': {
            ...title12,
            fontWeight: `400 ${imp}`,
            textTransform: `lowercase ${imp}`,
            minHeight: `0 ${imp}`,
            '&:first-letter': {
              textTransform: `uppercase ${imp}`,
            },
          },
          '&__desc': {
            padding: `12px 0${imp}`,
          },
          '&__rowCta': {
            alignItems: 'center',
            '& .quantitySelector': {
              marginLeft: 16,
            },
            '&>div': {
              marginLeft: 'auto',
            },
            '&--second-hand': {
              justifyContent: `flex-start ${imp}`,
              gap: `.5ch ${imp}`,
              alignItems: `baseline ${imp}`,
              fontSize: `10px ${imp}`,
            },
          },
          '&__cta': {
            marginLeft: 0,
            borderRadius: 0,
            color: `${black} ${imp}`,
            border: `1px solid ${black} ${imp}`,
            background: `none${imp}`,
            height: `24px ${imp}`,
            minWidth: `24px ${imp}`,
            '&:hover, &:focus': {
              background: `${black}${imp}`,
              color: `${white}${imp}`,
            },
            '&>span': {
              top: 0,
            },
            '& .MuiSvgIcon-root': {
              top: `0 ${imp}`,
              width: `14px ${imp}`,
              height: `14px ${imp}`,
            },
          },
        },
        '.shop': {
          '&__content': {
            marginTop: `0 ${imp}`,
            paddingTop: `0 ${imp}`,
            '&> .MuiContainer-root': {
              paddingTop: `10px ${imp}`,
              '@media (min-width: 1024px)': {
                paddingTop: `20px ${imp}`,
              },
            },
          },
          '&__area': {
            padding: `0 ${imp}`,
          },
          '&__planner': {
            display: `none ${imp}`,
          },
          '&__grid': {
            '@media (max-width: 1024px)': {
              marginTop: `0 ${imp}`,
            },
          },
        },
        '.product': {
          '&__container': {
            '@media (min-width: 1366px)': {
              maxWidth: `1440px ${imp}`,
              '&>.MuiContainer-root': {
                maxWidth: `1440px ${imp}`,
              },
            },
          },
          '&__main': {
            '@media (min-width: 1366px)': {
              marginLeft: `-48px ${imp}`,
            },
          },
          '&__left': {
            '@media (min-width: 1366px)': {
              paddingLeft: `0 ${imp}`,
            },
            '@media (min-width: 1024px)': {
              maxWidth: `54% ${imp}`,
              flexBasis: `54% ${imp}`,
            },
          },
          '&__right': {
            '@media (min-width: 1366px)': {
              maxWidth: `446px ${imp}`,
            },
          },
          '&__prev': {
            color: `${black} ${imp}`,
            border: `0 ${imp}`,
            padding: `0 ${imp}`,
            fontWeight: 500,
            fontSize: `10px ${imp}`,
            '& .MuiButton-label': {
              textDecoration: 'underline',
              textTransform: `none ${imp}`,
            },
          },
          '&__head': {
            flexWrap: `wrap ${imp}`,
            '&-price': {
              textAlign: `left ${imp}`,
            },
            '& .MuiGrid-item': {
              maxWidth: '100%',
              flexBasis: '100%',
              paddingTop: `4px ${imp}`,
              paddingBottom: `4px ${imp}`,
            },
            '@media (min-width: 1366px)': {
              paddingTop: 90,
              marginBottom: `0${imp}`,
            },
          },
          '&__title, &__price, &__state': {
            textTransform: `inherit ${imp}`,
            fontWeight: `700 ${imp}`,
            fontSize: `15px ${imp}`,
          },
          '&__title': {
            textTransform: `uppercase ${imp}`,
            marginBottom: `7px ${imp}`,
          },
          '&__price': {
            '&--original': {
              fontSize: `11px ${imp}`,
              fontWeight: `500 ${imp}`,
              lineHeight: `1.7 ${imp}`,
              letterSpacing: `.33px ${imp}`,
            },
          },
          '&__desc': {
            ...title11,
            fontWeight: `500 ${imp}`,
            textTransform: `inherit ${imp}`,
            lineHeight: `1.7 ${imp}`,
            '& *': {
              marginTop: `0${imp}`,
              marginBottom: `30px ${imp}`,
            },
            '& ul, & ol': {
              paddingLeft: '1em',
              '& li': {
                marginBottom: `0 ${imp}`,
              },
            },
          },
          '&__details': {
            marginTop: `0${imp}`,
          },
          '&-tabs': {
            '& .MuiTabs-indicator': {
              height: `1px ${imp}`,
            },
          },
          '&-tab': {
            '&.MuiTab-root': {
              paddingLeft: `0 ${imp}`,
              paddingRight: `0 ${imp}`,
              marginLeft: 30,
              position: 'relative',
              overflow: 'inherit',
              fontSize: `10px ${imp}`,
              '&:first-child': {
                marginLeft: 0,
                '&:before': {
                  display: 'none',
                },
              },
              '& p': {
                textTransform: `uppercase${imp}`,
              },
              '&:before': {
                position: 'absolute',
                left: -15,
                top: '50%',
                marginTop: '-.5em',
                content: '""',
                height: '1em',
                width: 1,
                backgroundColor: black,
              },
            },
          },
          '&-tabpanel': {
            '& .MuiBox-root': {
              paddingLeft: `0 ${imp}`,
              paddingRight: `0 ${imp}`,
            },
          },
        },
        '.product-related': {
          '&__title': {
            ...title14,
          },
        },
        '.date_form': {
          background: `none ${imp}`,
          display: `none ${imp}`,
        },
        '.daterange': {
          border: `1px solid ${black}`,
          color: black,
          '@media (max-width: 1024px)': {
            border: 0,
            marginLeft: `auto ${imp}`,
            marginRight: `auto ${imp}`,
            maxWidth: 320,
          },
          '&__icon': {
            color: `${black} ${imp}`,
            top: 1,
            '&--calendar': {
              display: `none ${imp}`,
            },
          },
          '& button': {
            fontWeight: 500,
            fontSize: `15px ${imp}`,
            letterSpacing: '2.2px',
            color: black,
          },
          '&__btn': {
            paddingTop: `11px ${imp}`,
            paddingBottom: `11px ${imp}`,
          },
        },
        '.hiw': {
          '&__icon': {
            minHeight: `52px ${imp}`,
          },
          '&__img': {
            width: `52px ${imp}`,
            height: `52px ${imp}`,
          },
          '@media (max-width: 1024px)': {
            textAlign: `center ${imp}`,
            padding: `8px 0px ${imp}`,
            flexDirection: `column ${imp}`,
            alignItems: `center ${imp}`,
            justifyContent: `center ${imp}`,
          },
          '@media (min-width: 1366px)': {},
          '&__head': {
            '@media (min-width: 480px)': {
              minHeight: `0 ${imp}`,
              paddingBottom: `0 ${imp}`,
            },
          },
          '&__title': {
            textTransform: 'uppercase',
            color: `${black} ${imp}`,
            fontSize: `22px ${imp}`,
            letterSpacing: `2.3px ${imp}`,
            fontWeight: `500 ${imp}`,
          },
          '&__desc': {
            letterSpacing: `.5px ${imp}`,
            lineHeight: `normal ${imp}`,
            fontSize: `14px ${imp}`,
            fontWeight: `600 ${imp}`,
            '& strong': {
              fontWeight: `600 ${imp}`,
            },
          },
        },
        '.section': {
          '&__spacer': {
            padding: `32px 0${imp}`,
            '@media (max-width: 1024px)': {
              padding: `6px 0${imp}`,
            },
          },
        },
        '.section-infos': {
          margin: `0 0 15px ${imp}`,
          '@media (max-width: 1024px)': {
            margin: `15px 0 0 ${imp}`,
          },
          '& h2': {
            ...heading('18px', '18px', 600, 600, fontMain, fontMain, true),
            letterSpacing: `.64px ${imp}`,
            lineHeight: `1.3 ${imp}`,
            color: `${black} ${imp}`,
          },
          '& h2, & p': {
            marginBottom: `35px ${imp}`,
            '@media (max-width: 1024px)': {
              marginBottom: `16px ${imp}`,
            },
          },
          '& ul': {
            ...listStyle,
          },
          '& .MuiButtonBase-root': {
            minWidth: `166px ${imp}`,
          },
          '&__content': {
            '@media (max-width: 1024px)': {
              maxWidth: `100% ${imp}`,
              padding: `2px 30px 10px ${imp}`,
            },
          },
        },
        '.section-hiw': {
          padding: `0${imp}`,
          '&>.MuiContainer-root': {
            '@media (min-width: 1366px)': {
              maxWidth: `830px ${imp}`,
            },
          },
          '&__spacing': {
            padding: `0${imp}`,
            '@media (max-width: 1024px)': {
              padding: `10px 0${imp}`,
            },
          },
          '&__title': {
            display: `none${imp}`,
          },
          '&__cta': {},
        },
        '.section-discover': {
          paddingTop: `0 ${imp}`,
          '&__subtext': {
            paddingBottom: `0 ${imp}`,
            '&--products': {
              paddingTop: `0 ${imp}`,
            },
          },
          '&__subtitle': {
            ...hpTitle,
            marginTop: `0 ${imp}`,
            color: `${black} ${imp}`,
            '@media (max-width: 1024px)': {
              ...title18,
            },
          },
          '&__desc': {
            '&:empty': {
              display: 'none',
            },
          },
          '& .swiper-slide': {
            marginBottom: `0 ${imp}`,
          },
        },
        '.section-menu': {
          padding: `16px 0 ${imp}`,
          '& .section-list': {
            textAlign: `center ${imp}`,
          },
          '& li': {
            marginRight: `18px ${imp}`,
          },
          '&__link, &__cta': {
            color: `${black} ${imp}`,
            ...title11,
            '&>span': {
              fontSize: `11px${imp}`,
              fontWeight: `600${imp}`,
              ...btn2ndHandOutlined,
            },
          },
          '&__link': {
            '&:hover, &:focus': {
              color: `${secondaryMain} ${imp}`,
            },
          },
          '&__cta': {
            whiteSpace: `nowrap ${imp}`,
            '&:before': {
              content: '""',
              background:
                'url("./images/maje/icon_back.svg") no-repeat 50% 50%',
              backgroundSize: 'contain',
              display: 'inline-block',
              verticalAlign: '-15%',
              width: 10,
              height: 13,
              marginRight: 2,
              fontSize: `10px ${imp}`,
              '@media (min-width: 1024px)': {
                verticalAlign: '-20%',
                marginRight: 11,
                width: 16,
                fontSize: `11px ${imp}`,
              },
            },
          },
        },
        '#hero-sections': {
          '&>div': {
            minHeight: `0 ${imp}`,
          },
          '& .content': {
            color: black,
            maxWidth: 746,
            margin: 'auto',
            lineHeight: 2.3,
            letterSpacing: '1px',
            fontWeight: 500,
            '& h2': {
              ...titleWithLogo,
            },
            '& h2, & p': {
              '@media (min-width: 1024px)': {
                margin: `42px auto ${imp}`,
              },
            },
          },
          '& .section-hero__overlay': {
            display: 'none',
          },
          '& #section-hero': {
            '&--2': {
              minHeight: `100vh ${imp}`,
            },
          },
        },
        '.discover': {
          '@media (max-width: 1024px)': {
            '&:active, &:focus, &:hover': {
              opacity: 0.7,
              transition: 'opacity .25s ease',
            },
          },
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
            '&:before': {
              background: `none ${imp}`,
            },
            '@media (max-width: 1024px)': {
              margin: `0 10px ${imp}`,
            },
          },
          '&__title': {
            position: `static${imp}`,
            padding: `16px 0 ${imp}`,
            background: `none ${imp}`,
            color: `${black} ${imp}`,
            textAlign: `left${imp}`,
            ...title11,
          },
        },
        '.discover-product': {
          '&__title': {
            ...title10,
          },
          '&__content': {
            position: `static${imp}`,
            padding: `16px 0 26px ${imp}`,
            background: `none ${imp}`,
            color: `${black} ${imp}`,
            textAlign: `left${imp}`,
            ...title11,
            '& .MuiTypography-root': {
              color: `${black} ${imp}`,
              fontWeight: `600 ${imp}`,
            },
          },
        },
        '.shipping-infos': {
          '&__start, &__end': {
            '.cart-shipping.cart-shipping--absolute &': {
              background: `none ${imp}`,
            },
            '.cart-shipping &': {
              background: `${greyLight}${imp}`,
            },
            '& .MuiTypography-body2': {
              ...title12,
              fontWeight: 500,
              letterSpacing: '1px',
              textTransform: `uppercase ${imp}`,
              '.cart-shipping.cart-shipping--absolute &': {
                fontSize: `11px ${imp}`,
                letterSpacing: '1px',
                textTransform: `none ${imp}`,
                marginLeft: `14px ${imp}`,
              },
              '.cart-shipping &': {
                fontSize: `11px ${imp}`,
                textTransform: `none ${imp}`,
                fontWeight: 400,
                letterSpacing: '1px',
              },
              '@media (max-width: 1024px)': {
                fontSize: `9px ${imp}`,
                letterSpacing: '1px',
              },
            },
            '& .MuiTypography-body1': {
              ...title14,
              letterSpacing: '1.5px',
            },
          },
        },
        '.delivery': {
          justifyContent: `flex-start ${imp}`,
        },
        '.deliveryDate, .returnDate': {
          ...title10,
          color: `${black} ${imp}`,
          background: `${greyLight} ${imp}`,
          marginLeft: 15,
          letterSpacing: `normal ${imp}`,
          '&>strong': {
            color: `${secondaryMain} ${imp}`,
          },
        },
        '.deliveryTitle': {
          '&.MuiTypography-root': {
            color: `${green} ${imp}`,
            textTransform: 'none',
            fontSize: 20,
            letterSpacing: `2px ${imp}`,
          },
        },
        '.label': {
          fontSize: `9px ${imp}`,
        },
        '.faq': {
          '&__item': {
            marginBottom: `0${imp}`,
          },
          '&__question': {
            fontSize: `10px ${imp}`,
            fontWeight: `500 ${imp}`,
            lineHeight: `1.3 ${imp}`,
            letterSpacing: `1.49px ${imp}`,
            borderBottom: `1px solid ${secondaryMain} ${imp}`,
            margin: `0 ${imp}`,
            padding: `18px 0 ${imp}`,
            textTransform: `uppercase ${imp}`,
            '&:hover, &:focus': {
              background: `none ${imp}`,
            },
            '&[aria-expanded=true]': {
              color: `${black} ${imp}`,
              background: `none${imp}`,
              borderColor: `transparent ${imp}`,
              '&>.MuiButton-label:after': {
                transform: 'rotate(-180deg)',
              },
            },
            '&>.MuiButton-label': {
              minHeight: `26px ${imp}`,
              display: 'flex',
              alignItems: 'center',
              //textTransform: 'uppercase',
              '& p': {
                display: 'block',
              },
              '& strong': {
                fontWeight: `500 ${imp}`,
              },
              '&:after': {
                content: '""',
                background:
                  'no-repeat 50% 50% url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxMCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPmZsZWNoZV9GQVE8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlNlY29uZGVNLUZBUSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNTkuMDAwMDAwLCAtNjIzLjAwMDAwMCkiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9ImZsZWNoZV9GQVEiIHBvaW50cz0iMTE2OCA2MjQgMTE2NCA2MjggMTE2MCA2MjQiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=")',
                marginRight: 24,
                marginLeft: 24,
                width: 13,
                height: 6,
                backgroundSize: `contain ${imp}`,
                display: `none ${imp}`,
              },
            },
          },
          '&__btn': {
            border: `0 ${imp}`,
            color: `${parme} ${imp}`,
          },
          '&__answer': {
            fontSize: `12px ${imp}`,
            fontWeight: `500 ${imp}`,
            letterSpacing: `0.36px ${imp}`,
            lineHeight: `${19 / 12} ${imp}`,
            padding: `0 0 20px ${imp}`,
            margin: `0 ${imp}`,
            borderBottom: `1px solid ${secondaryMain} ${imp}`,
            borderColor: `transparent ${imp}`,
            color: `${black} ${imp}`,
            '@media (min-width: 1024px)': {
              letterSpacing: `1.79px ${imp}`,
            },
          },
          '&-wrap': {
            paddingTop: `24px ${imp}`,
          },
        },
        '.section-faq': {
          '& .MuiContainer-root': {
            paddingLeft: 9,
            paddingRight: 9,
            '@media (min-width: 1024px)': {
              paddingLeft: 0,
              paddingRight: 0,
              maxWidth: `1070px ${imp}`,
            },
            '& .MuiContainer-root': {
              borderTop: `1px solid ${imp}`,
              paddingLeft: 0,
              paddingRight: 0,
              maxWidth: `1070px ${imp}`,
            },
          },
          '&__cta': {
            marginTop: `24px ${imp}`,
          },
          '&__title': {
            ...title18,
            color: `${secondaryMain} ${imp}`,
            textAlign: 'center',
            marginBottom: '10px',
          },
        },
        '#menu-toggler': {
          borderRadius: 0,
          height: `20px ${imp}`,
          width: `20px ${imp}`,
          marginLeft: `8px ${imp}`,
          '@media (min-width: 1366px)': {
            marginLeft: `1.25rem ${imp}`,
          },
          '& .MuiButton-label': {
            top: `0 ${imp}`,
            '& .injected-svg': {
              width: `17px ${imp}`,
              height: `19px ${imp}`,
            },
          },
          '.header--transparent &': {
            //...iconCartWhite,
          },
          '& .cart-badge': {
            display: `flex ${imp}`,
            alignItems: `center ${imp}`,
            justifyContent: `center ${imp}`,
            textAlign: `center ${imp}`,
            letterSpacing: `normal ${imp}`,
            background: `${black} ${imp}`,
            color: `${white} ${imp}`,
            border: `0 ${imp}`,
            padding: `0 ${imp}`,
            minWidth: `16px ${imp}`,
            paddingTop: `2px ${imp}`,
            height: `16px ${imp}`,
            lineHeight: `16px ${imp}`,
            borderRadius: `8px ${imp}`,
            right: `-8px ${imp}`,
            bottom: `-8px ${imp}`,
            fontSize: `10px ${imp}`,
            fontWeight: `400 ${imp}`,
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {
            '@media (min-width: 1024px)': {},
          },
        },
        '.cart-count': {
          color: `${black} ${imp}`,
          fontSize: `15px ${imp}`,
          letterSpacing: `1.5px ${imp}`,
        },
        '.cart-reset': {
          ...title10,
          textTransform: `none ${imp}` as any,
          textDecoration: `underline ${imp}`,
          '&:hover, &:focus': {
            background: `none ${imp}`,
          },
        },
        '[data-testid="continue"]': {
          ...btn2ndHand,
        },
        '.basket': {
          minHeight: `0 ${imp}`,
          '&__title, &-product__total': {
            fontWeight: `600 ${imp}`,
            fontSize: `12px${imp}`,
            '&>span': {
              fontWeight: `600 ${imp}`,
              color: `${black} ${imp}`,
            },
          },
          '&-product__total': {
            color: `${black} ${imp}`,
          },
          '&__time, &__time > span': {
            ...title10,
          },
          '&__icon': {
            height: `19px ${imp}`,
            width: `auto ${imp}`,
          },
        },
        '.cart-total--dad': {
          '& .price__amount': {
            color: `${secondaryMain} ${imp}`,
          },
        },
        '.page--home .footer, .page--faq .footer': {
          '@media (min-width: 1366px)': {
            paddingTop: 40,
          },
        },
        '.footer': {
          background: `${white} ${imp}`,
          '&__topbtn': {
            '@media (min-width: 1366px)': {
              minWidth: `160px ${imp}`,
            },
          },
          '&__copyright': {
            background: `${white} ${imp}`,
          },
          '&__top': {
            display: `none ${imp}`,
            background: `none${imp}`,
            '& .MuiGrid-container': {
              justifyContent: 'flex-end',
              '@media (max-width: 1024px)': {
                justifyContent: 'center',
              },
              '&> .MuiGrid-item': {
                maxWidth: 'none',
                flexBasis: 'auto',
                '@media (max-width: 1024px)': {
                  '&>a': {
                    marginTop: 0,
                  },
                },
              },
            },
            '& .MuiTypography-root': {
              color: `${black} ${imp}`,
              textAlign: 'right',
              fontSize: `12px ${imp}`,
              fontWeight: `500 ${imp}`,
              letterSpacing: `.16px ${imp}`,
              '& p': {
                margin: `0 ${imp}`,
              },
              '& strong': {
                fontWeight: `500 ${imp}`,
              },
              '@media (max-width: 767px)': {
                maxWidth: `275px ${imp}`,
              },
              '@media (max-width: 1024px)': {
                textAlign: 'center',
              },
            },
            '& .MuiButtonBase-root': {
              '@media (max-width: 1024px)': {
                marginTop: `0 ${imp}`,
              },
            },
          },
          '&__bottom': {
            background: `${white} ${imp}`,
            borderTop: `1px solid ${secondaryMain}  ${imp}`,
            marginTop: `0  ${imp}`, //car marginTop négative imposée par l'outil => bizarre
            '&>.MuiContainer-root': {
              '@media (min-width: 1024px)': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              },
            },
            '@media (max-width: 1024px)': {
              padding: `30px 0 24px ${imp}`,
            },
          },
          '&__logo': {
            margin: `auto ${imp}`,
            height: `23px ${imp}`,
          },
          '&__bottomRow1': {
            '@media (min-width: 1024px)': {
              marginTop: -2,
            },
          },
          '&__bottomRow2': {
            '@media (min-width: 1024px)': {
              marginTop: `0 ${imp}`,
              marginLeft: `40px ${imp}`,
            },
            '@media (max-width: 1024px)': {
              marginTop: `12px ${imp}`,
            },
          },
          '&__bottomRow1Col:empty': {
            display: `none ${imp}`,
          },
          '&__bottomRow2Col': {
            '&:nth-child(1)': {
              display: `none ${imp}`,
            },
            '&:nth-child(2)': {
              '@media (max-width: 1024px)': {
                textAlign: `center ${imp}`,
                marginBottom: `0 ${imp}`,
                '& a': {
                  display: 'block',
                  margin: `14px 0 ${imp}`,
                  padding: `4px 8px ${imp}`,
                  letterSpacing: `.3px ${imp}`,
                  '&:last-child': {
                    marginBottom: `0 ${imp}`,
                  },
                },
              },
              '& a': {
                fontSize: `10px ${imp}`,
                letterSpacing: `1.5px ${imp}`,
                fontWeight: `500 ${imp}`,
                '&+a': {
                  '@media (min-width: 1366px)': {
                    marginLeft: `70px ${imp}`,
                  },
                },
              },
            },
          },
        },
        '.hero--shop': {
          minHeight: `0 ${imp}`,
          '& .MuiContainer-root': {
            minHeight: `0 ${imp}`,
          },
          '@media (min-width: 1366px)': {
            minHeight: `0 ${imp}`,
            '& .MuiContainer-root': {
              minHeight: `0 ${imp}`,
              justifyContent: `flex-start ${imp}`,
            },
          },
          '& .hero__toptitle': {
            '@media (max-width: 1024px)': {
              marginBottom: 0,
              position: `absolute ${imp}`,
              top: 2,
              left: 16,
              fontSize: `7.5px ${imp}`,
            },
          },
          '& .hero__title': {
            color: `${green} ${imp}`,
            fontWeight: 600,
            fontSize: `23px ${imp}`,
            letterSpacing: `2.38px ${imp}`,
            '@media (min-width: 1024px)': {
              fontSize: `32px ${imp}`,
              letterSpacing: `3.3px ${imp}`,
            },
          },
        },
        '.filter': {
          '&:hover': {
            backgroundColor: `${secondaryMain} ${imp}`,
          },
          '&.active': {
            color: `${black}${imp}`,
            '&:hover': {
              backgroundColor: `${secondaryMain} ${imp}`,
            },
          },
        },
        '.productCategories': {
          '& .MuiTabs-scroller': {
            '& button': {
              ...btn2ndHand,
              ...btn2ndHandOutlined,
              padding: `5px 12px 3px ${imp}`,
              fontWeight: `600 ${imp}`,
              lineHeight: `${13 / 10} ${imp}`,
              fontSize: `10px ${imp}`,
              letterSpacing: `1px ${imp}`,
              minHeight: `27px ${imp}`,
              '& .MuiTab-wrapper': {
                position: 'relative',
                top: 1,
              },
              '& + button': {
                '@media (min-width: 1366px)': {
                  marginLeft: `18px ${imp}`,
                },
              },
              '&[aria-selected=true]': {
                borderColor: `${secondaryMain} ${imp}`,
              },
            },
          },
        },
        '.sticky-bar': {
          '& .filters-menu': {
            paddingBottom: `0 ${imp}`,
          },
          '& .onlyAvailable': {
            marginTop: `0 ${imp}`,
          },
          '&--sticked': {
            '@media (max-width: 1024px)': {
              top: `${headerHeightMobile} ${imp}`,
              '&.sticky-bar--scrollup': {
                top: `0 ${imp}`,
              },
            },
          },
          '&--shop': {
            paddingBottom: `0 ${imp}`,
          },
        },
        '.onlyAvailable, .filters--inline': {
          '&:before': {
            content: '""',
            position: 'absolute',
            width: '100vw',
            transform: 'translateX(-50%) translateY(0)',
            left: '50%',
            bottom: 0,
            borderBottom: `1px solid ${greyAlt}`,
          },
        },
        '.filters': {
          '&--inline': {
            marginLeft: `0 ${imp}`,
            position: 'relative',
            '&>div:first-child': {
              display: 'none',
            },
            '@media (max-width: 1024px)': {
              marginTop: `0 ${imp}`,
            },
          },
          '&__dropdown': {
            ...title11,
            border: `0 ${imp}`,
            color: `${black} ${imp}`,
          },
        },
        '.onlyAvailable': {
          '&__check': {
            color: `${black} ${imp}`,
          },
          '&__label': {
            ...title11,
            textTransform: `none ${imp}`,
          },
        },
        '.funnel': {
          '&__header': {
            minHeight: `54px ${imp}`,
            background: `${white} ${imp}`,
            '& .MuiTypography-root': {
              ...title14,
              fontSize: `21px ${imp}`,
              letterSpacing: `1px ${imp}`,
              color: `${black} ${imp}`,
              position: 'relative',
              top: 2,
            },
          },
          '&__rental-summary, & .deliveryDate, & .returnDate': {
            display: `none ${imp}`,
          },
          '&__column': {
            '&--delivery, &--return': {
              flexBasis: `100% ${imp}`,
              maxWidth: `100% ${imp}`,
            },
          },

          //paul 8 avril 2024
          '&__nav--active': {
            color: `${green} ${imp}`,
            borderColor: `${green} ${imp}`,
          },
        },
        '.commitment': {
          paddingTop: `28px ${imp}`,
          paddingBottom: `28px ${imp}`,
          background: `#F2F2F2 ${imp}`,
          borderRadius: `4px ${imp}`,
          '&__title': {
            ...title14,
            textTransform: `none ${imp}`,
            marginBottom: `10px ${imp}`,
          },
          '&__desc': {
            ...title10,
          },
          '&__text': {
            textAlign: `center ${imp}`,
          },
        },
        '.payment-coupon, .payment-bycard': {
          width: `auto ${imp}`,
          '@media (min-width: 1366px)': {
            width: `60% ${imp}`,
          },
        },
        '.payment-coupon': {
          '& .MuiGrid-root.MuiGrid-container': {
            //justifyContent: 'center',
          },
        },
        '.payment-bycard': {
          '& .MuiCheckbox-root + .MuiFormControlLabel-label .MuiTypography-root':
            {
              fontSize: `11px ${imp}`,
              letterSpacing: `0.6 ${imp}`,
              lineHeight: `1.5 ${imp}`,
            },
          '& form>div': {
            display: `flex ${imp}`,
            flexDirection: `column ${imp}`,
            gap: `10px ${imp}`,
          },
        },
        '.takeBack': {
          backgroundColor: `${white} ${imp}`,
          '@media (min-width: 1366px)': {
            padding: `0 0 50px ${imp}`,
          },
          '& .label, &__label--select': {
            fontSize: `14px ${imp}`,
            paddingLeft: `0 ${imp}`,
            fontWeight: `600 ${imp}`,
            letterSpacing: `0.5px ${imp}`,
            marginBottom: `12px ${imp}`,
            '@media (min-width: 768px)': {
              textAlign: `center ${imp}`,
            },
          },
          '&__select': {
            ...customSelect,
          },
          '&__content': {
            padding: `0 ${imp}`,
          },
          '&__error': {
            padding: `14px 80px ${imp}`,
            color: `${black} ${imp}`,
            background: `${greyLight} ${imp}`,
            textAlign: `center ${imp}`,
            fontSize: `11px ${imp}`,
            lineHeight: `1.55 ${imp}`,
            letterSpacing: `0.3px ${imp}`,
            marginTop: `20px ${imp}`,
            '& .MuiSvgIcon-root': {
              display: `none ${imp}`,
            },
            '@media (max-width: 1024px)': {
              padding: `16px 9px ${imp}`,
            },
            '&--searchRef': {
              '@media (min-width: 768px)': {
                marginTop: `0 ${imp}`,
                marginLeft: `-${674 / 2}px ${imp}`,
                marginRight: `-${674 / 2}px ${imp}`,
                width: `674px ${imp}`,
              },
            },
          },
          '&__header': {
            marginBottom: `0 ${imp}`,
            '&__title': {
              textTransform: `uppercase ${imp}`,
              fontSize: `16px ${imp}`,
              color: `${secondaryMain} ${imp}`,
              '@media (max-width: 1024px)': {
                fontSize: `12px ${imp}`,
                letterSpacing: `.4px ${imp}`,
              },
            },
            '&__text': {
              '@media (max-width: 1024px)': {
                fontSize: `10px ${imp}`,
                letterSpacing: `0.6px ${imp}`,
                color: `${greyText} ${imp}`,
              },
            },
          },
          '&__back-btn': {
            marginBottom: `0 ${imp}`,
          },
          '&__nav': {
            maxWidth: `342px ${imp}`,
            marginLeft: `auto ${imp}`,
            marginRight: `auto ${imp}`,
            paddingTop: `14px ${imp}`,
            '&__separtor': {
              ...title10,
              fontWeight: `500 ${imp}`,
              color: black,
            },
            '&__item': {
              display: `block ${imp}`,
              textTransform: `uppercase ${imp}`,
              fontWeight: `900 ${imp}`,
              fontSize: `12px ${imp}`,
              letterSpacing: `.28px ${imp}`,
              color: `${greyText} ${imp}`,
              '&--active': {
                color: `${black} ${imp}`,
                borderBottom: `1px solid ${black} ${imp}`,
              },
              '@media (min-width: 768px)': {
                padding: `0 ${imp}`,
              },
              '&:first-child': {
                '&:before': {
                  display: 'none',
                },
              },
            },
            '&:after': {
              display: `none ${imp}`,
            },
            '@media (min-width: 1024px)': {
              gap: `25px ${imp}`,
            },
            '&__step': {
              margin: `0 .5ch 0 0 ${imp}`,
              padding: `0 ${imp}`,
              width: `auto ${imp}`,
              height: `auto ${imp}`,
              minWidth: `0 ${imp}`,
              minHeight: `0 ${imp}`,
              background: `none ${imp}`,
              color: `${greyText} ${imp}`,
              '&::after': {
                content: "'.'",
              },
              '&--active': {
                color: `${black} ${imp}`,
              },
            },
          },
          '&__required-field': {
            fontSize: `9px ${imp}`,
            fontWeight: `500 ${imp}`,
            color: `${black} ${imp}`,
            fontStyle: `italic ${imp}`,
            letterSpacing: `normal ${imp}`,
          },
          '&-item-title': {
            ...title14,
            color: `${secondaryMain} ${imp}`,
            fontWeight: `700 ${imp}`,
            letterSpacing: `normal ${imp}`,
            '@media (min-width: 768px)': {
              textAlign: `center ${imp}`,
            },
          },
          '&__firstStep': {
            '&--pending': {
              maxWidth: `342px ${imp}`,
              marginLeft: `auto ${imp}`,
              marginRight: `auto ${imp}`,
              '@media (min-width: 768px)': {},
            },
            '&--success': {
              '@media (max-width: 1024px)': {
                maxWidth: `342px ${imp}`,
                marginLeft: `auto ${imp}`,
                marginRight: `auto ${imp}`,
              },
            },
            '&__container': {
              maxWidth: `674px ${imp}`,
              '@media (min-width: 768px)': {
                marginLeft: `auto ${imp}`,
                marginRight: `auto ${imp}`,
              },
              '&--success': {
                maxWidth: `694px ${imp}`,
              },
            },
            '&__submit': {
              marginTop: `25px ${imp}`,
            },
          },
          '&__preliminaryQuestions': {},
          '&__preliminaryQuestion': {
            marginBottom: `25px ${imp}`,
            '& .MuiFormGroup-root': {
              gap: `14px ${imp}`,
            },
            '&__title': {
              letterSpacing: `0.5px ${imp}`,
              marginBottom: `18px ${imp}`,
              textTransform: `inherit ${imp}`,
              '@media (min-width: 1024px)': {
                textAlign: `center ${imp}`,
                marginBottom: `38px ${imp}`,
              },
            },
            '&__text': {
              fontWeight: `500 ${imp}`,
              letterSpacing: `0.3px ${imp}`,
              '@media (max-width: 1024px)': {
                fontSize: `11px ${imp}`,
                lineHeight: `1.55 ${imp}`,
              },
            },
            '&__answer': {
              '& .MuiTypography-body1': {
                paddingTop: `4px ${imp}`,
                fontSize: `10px ${imp}`,
                fontWeight: `500 ${imp}`,
                letterSpacing: `0.33px ${imp}`,
                '@media (max-width: 1024px)': {
                  fontSize: `9px ${imp}`,
                  paddingTop: `1px ${imp}`,
                },
              },
            },
            '@media (max-width: 1024px)': {
              '&__answer': {
                flex: `0 0 110px ${imp}`,
                '&> p': {
                  fontSize: `10px ${imp}`,
                  letterSpacing: `0.6px ${imp}`,
                  lineHeight: `1.9 ${imp}`,
                },
              },
            },
          },
          '&__confirmation_image': {
            '&__title': {
              letterSpacing: `normal ${imp}`,
              fontSize: `11px ${imp}`,
              fontWeight: `700 ${imp}`,
              lineHeight: `1.27 ${imp}`,
            },
          },
          '&-offer': {
            '&__title': {
              ...title14,
              color: `${secondaryMain} ${imp}`,
              fontWeight: `700 ${imp}`,
              letterSpacing: `normal ${imp}`,
            },
          },
          '&__location': {
            '&__title': {
              ...title14,
              color: `${secondaryMain} ${imp}`,
              fontWeight: `700 ${imp}`,
              letterSpacing: `0.42px ${imp}`,
              lineHeight: `1.29 ${imp}`,
              marginBottom: `6px ${imp}`,
            },
            '&__explanation': {
              fontSize: `11px ${imp}`,
              letterSpacing: `.33px ${imp}`,
              lineHeight: `1.45 ${imp}`,
              marginBottom: `14px ${imp}`,
            },
            '&__choice': {
              '& .MuiTypography-caption': {
                fontWeight: `500 ${imp}`,
                fontSize: `11px ${imp}`,
                letterSpacing: `.3px ${imp}`,
                color: `${black} ${imp}`,
              },
            },
          },
          '&-faq': {
            '&__title': {
              textTransform: `inherit ${imp}`,
              marginBottom: `0 ${imp}`,
              letterSpacing: `0.5px ${imp}`,
              '@media (min-width: 1024px)': {
                textAlign: `center ${imp}`,
                letterSpacing: `1.45px ${imp}`,
              },
            },
            '&__list': {
              borderTop: `1px solid ${secondaryMain} ${imp}`,
              marginTop: `12px ${imp}`,
            },
          },
          '&-dialog': {
            // '& .MuiDialog-paper': {
            //   '@media (max-width: 320px)': {
            //     margin: `15px ${imp}`,
            //   },
            // },
            '&__header': {
              background: `${white} ${imp}`,
              color: `${black} ${imp}`,
              marginBottom: `0 ${imp}`,
              position: `absolute ${imp}`,
              top: `0 ${imp}`,
              right: `0 ${imp}`,
              '&__close': {
                '&-text': {
                  display: `none ${imp}`,
                },
                '&-icon': {
                  background: 'url("/images/maje/close.svg") no-repeat 50% 50%',
                },
              },
            },
            '&__content': {
              textAlign: `center ${imp}`,
              '& h2, & h3': {
                color: `${secondaryMain} ${imp}`,
                lineHeight: `1.15 ${imp}`,
                letterSpacing: `1.4px ${imp}`,
                '@media (max-width: 1024px)': {
                  fontSize: `11px ${imp}`,
                },
              },
            },
            '&__title': {
              fontWeight: `700 ${imp}`,
              color: `${secondaryMain} ${imp}`,
              '@media (max-width: 1024px)': {
                fontSize: `11px ${imp}`,
              },
              '& .MuiSvgIcon-root': {
                display: `none ${imp}`,
              },
            },
            '&__wysiwyg': {
              '@media (max-width: 1024px)': {
                fontSize: `10px ${imp}`,
                '& p': {
                  margin: `0 0 5px ${imp}`,
                },
              },
              '& img': {
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
                margin: 'auto',
                marginBottom: '1rem',
              },
            },
          },
          '&__help-grid': {
            marginTop: `8px ${imp}`,
          },
          '&__help-link': {
            fontSize: `11px ${imp}`,
            fontWeight: `500 ${imp}`,
            padding: `4px 0 4px 24px ${imp}`,
            background: `url("/images/maje/icon_help.svg") no-repeat 0 50% ${imp}`,
          },
          '&__states__explanation--link': {
            fontWeight: `400 ${imp}`,
          },
          '&-searchRef': {
            flexDirection: 'column',
            alignItems: `center ${imp}`,
            '&__input': {
              maxWidth: `342px ${imp}`,
              '& .input__icon--success': {
                background: `url("/images/maje/input_icon_success.svg") 50% 50% no-repeat ${imp}`,
                bottom: `4px ${imp}`,
                '& .MuiSvgIcon-root': {
                  visibility: `hidden ${imp}`,
                },
              },
              '& .label strong': {
                fontWeight: `600 ${imp}`,
                textDecoration: `underline ${imp}`,
              },
              '& .input::placeholder': {
                color: `${greyText} ${imp}`,
                opacity: `1 ${imp}`,
              },
            },
            '& .input': {},
          },
          '&__variant__select': {
            flexDirection: 'column',
            alignItems: `center ${imp}`,
            '& .makeStyles-selector-31': {
              fontSize: `11px ${imp}`,
            },
          },
          '&__state__select': {
            flexDirection: 'column',
            alignItems: `center ${imp}`,
          },
          '& .account-card': {
            margin: `0 auto ${imp}`,
            padding: `0 ${imp}`,
            '&.forgot-card': {
              width: `300px ${imp}`,
              '@media (max-width: 1024px)': {
                width: `100% ${imp}`,
              },
              '& form>div': {
                width: '100%',
                '&>.MuiGrid-root': {
                  '@media (max-width: 1024px)': {
                    display: `block ${imp}`,
                  },
                },
              },
            },
            '&.register-card': {
              '@media (min-width: 1024px)': {
                width: `100% ${imp}`,
              },
            },
            '& .account__fields': {
              '@media (min-width: 1024px)': {
                padding: `20px 0 ${imp}`,
              },
              '@media (max-width: 1024px)': {
                maxWidth: `100% ${imp}`,
              },
            },
            '& .MuiButton-containedSecondary': {
              paddingTop: `7px ${imp}`,
              paddingBottom: `8px ${imp}`,
              '@media (max-width: 1024px)': {
                width: `100% ${imp}`,
              },
            },
          },
          '& .account__title.MuiTypography-root': {
            ...title14,
            color: `${secondaryMain} ${imp}`,
            fontWeight: `700 ${imp}`,
            letterSpacing: `0.42px ${imp}`,
            lineHeight: `1.29 ${imp}`,
            marginBottom: `6px ${imp}`,
          },
          '& .MuiFormControlLabel-root': {
            marginLeft: `-2px ${imp}`,
          },
          '& .account__desc': {
            fontWeight: `400 ${imp}`,
          },
          '&__secondStep': {
            gap: `10px ${imp}`,
            '&__container': {
              maxWidth: `698px ${imp}`,
              margin: `0 auto ${imp}`,
            },
            '& .product__name': {
              fontSize: `11px ${imp}`,
              lineHeight: `1.27 ${imp}`,
              fontWeight: `700 ${imp}`,
              letterSpacing: `normal ${imp}`,
              '@media (min-width: 1024px)': {
                maxWidth: `154px ${imp}`,
              },
            },
            '& .product__description': {
              '& .MuiTypography-body2': {
                fontSize: `11px ${imp}`,
                letterSpacing: `.73px ${imp}`,
              },
            },
            '& .product__delete': {
              '& .MuiSvgIcon-root': {
                '&>path': {
                  display: `none ${imp}`,
                  visibility: `hidden ${imp}`,
                },
                background: `url("/images/maje/croix.png") no-repeat 50% 50% ${imp}`,
                backgroundSize: `16px 16px ${imp}`,
              },
            },
            '& .takeBack__price': {
              fontWeight: `700 ${imp}`,
              fontSize: `14px ${imp}`,
              lineHeight: `15px ${imp}`,
              letterSpacing: `.46px ${imp}`,
              color: `${black} ${imp}`,
            },
            '& .products__total__amount': {
              fontSize: `11px ${imp}`,
              letterSpacing: `.36px ${imp}`,
            },
            '& .account__desc, & .label': {
              display: `none ${imp}`,
            },
            '& .input': {},
          },
          '&__add-product-btn': {
            paddingLeft: `20px ${imp}`,
            paddingRight: `20px ${imp}`,
          },
          '&__formLabelRow': {
            // marginBottom: `15px ${imp}`,
            '@media (min-width: 1024px)': {
              // marginBottom: `25px ${imp}`,
            },
            '& .paragraph-class': {
              fontSize: `11px ${imp}`,
              letterSpacing: `.33 ${imp}`,
              lineHeight: `1.45 ${imp}`,
            },
            '& .MuiFormControlLabel-root': {
              gap: `15px ${imp}`,
              '@media (min-width: 1024px)': {
                gap: `90px ${imp}`,
              },
            },
          },
          '&__thirdStep': {
            '@media (max-width: 1024px)': {
              gap: `12px ${imp}`,
              marginTop: `-20px ${imp}`,
            },
            '&_confirmation_title': {
              color: `${secondaryMain} ${imp}`,
              fontWeight: `700 ${imp}`,
              marginBottom: `0 ${imp}`,
            },
            '&_confirmation_desc': {
              textAlign: `center ${imp}`,
              '@media (max-width: 1024px)': {
                padding: `0 ${imp}`,
              },
            },
            '& .MuiSvgIcon-root': {
              display: `none ${imp}`,
            },
            '& #section-columns-0': {
              '&>h3': {
                color: `${secondaryMain} ${imp}`,
              },
            },
            '& .step__explication__title, & .step__explication__title h3': {
              color: secondaryMain,
              margin: `0 0 9px ${imp}`,
              fontSize: `12px ${imp}`,
              letterSpacing: `.36 ${imp}`,
            },
            '& .MuiButtonBase-root': {
              paddingTop: `7px ${imp}`,
              paddingBottom: `8px ${imp}`,
              '@media (min-width: 1024px)': {
                minWidth: `336px ${imp}`,
              },
              '@media (max-width: 1024px)': {
                width: `100% ${imp}`,
              },
            },
            '&__section': {
              '@media (max-width: 1024px)': {
                padding: `0 ${imp}`,
              },
            },
          },
        },
        '.take-back-confirmation-modal': {
          '&__container': {
            maxWidth: '700px !important',
          },
          '&__title': {
            padding: '2rem 2rem 0.8rem 2rem !important',
            textAlign: 'center',
            fontWeight: '700!important' as unknown as 700,
            fontSize: '.875rem!important',
            lineHeight: '1.2rem!important',
            letterSpacing: '1.3125px!important',
            textTransform: 'uppercase',
            position: 'relative',
          },
          '&__closeButton': {
            position: 'absolute !important' as unknown as 'absolute',
            top: '0.4rem',
            right: '0.4rem',
            borderRadius: '2px !important',
          },
          '&__body': {
            paddingRight: '2rem !important',
            paddingLeft: '2rem !important',
            textAlign: 'center',
            lineHeight: '1.4rem',
          },
          '&__actions': {
            paddingRight: '2rem !important',
            paddingLeft: '2rem !important',
            paddingBottom: '2rem !important',
            justifyContent: 'center !important',
          },
          '&__actionButton': {
            backgroundColor: 'black !important',
            color: 'white !important',
            padding: '0.4rem 0.8rem !important',
            borderRadius: '4px !important',
            width: '40%',
            height: '48px!important',
            maxWidth: '300px',
            textTransform: 'none !important' as unknown as 'none',
          },
          '@media (max-width: 768px)': {
            '&__actionButton': {
              width: '100%',
            },
          },
        },
        '.warning-adress__container': {
          background: `inherit ${imp}`,
          color: `${black} ${imp}`,
          border: `${black} 1px solid`,
          '& .MuiButtonBase-root ': {
            color: `${black} ${imp}`,
            backgroundColor: '#e0e0e0',
          },
        },
        '.invoice--download__icon': {
          display: 'none',
        },
        '.payment, .information, .shipping, .account, .faq-page, .contact-page, .confirmation':
          {
            background: `${white} ${imp}`,
            '@media (min-width: 1024px)': {
              paddingTop: `42px ${imp}`,
              paddingBottom: `42px ${imp}`,
            },
          },
        '.account, .account-infos, .contact-page': {
          '&__subtitle': {
            display: `none ${imp}`,
          },
          '&__title': {
            '&.MuiTypography-root': {
              color: `${green} ${imp}`,
              '@media (min-width: 1024px)': {
                ...title14,
                marginBottom: `8px ${imp}`,
              },
            },
          },
          '&-card': {
            '@media (min-width: 1024px)': {
              padding: `48px 50px${imp}`,
            },
            '& .account__fields': {
              padding: `12px 0 ${imp}`,
              '@media (min-width: 1024px)': {
                padding: `48px 0 ${imp}`,
              },
            },
          },
          '&__switch, &__forgot': {
            ...title10,
            textDecoration: `underline ${imp}`,
          },
          '&__switch': {
            textTransform: `uppercase ${imp}`,
          },
          '&__desc': {
            maxWidth: 300,
            marginLeft: `auto ${imp}`,
            marginRight: `auto ${imp}`,
            textAlign: `center ${imp}`,
            '&>b': {
              fontWeight: `500 ${imp}`,
            },
          },
        },
        '.account': {
          '&__title': {
            '&.MuiTypography-root': {
              '@media (min-width: 1024px)': {
                textTransform: `capitalize ${imp}`,
              },
            },
          },
          '&__bottom-text': {
            textAlign: 'center',
          },
          '&__box': {
            padding: `20px 0 ${imp}`,
          },
        },
        '.account-infos': {
          '&__title': {
            '&.MuiTypography-root': {
              color: `${secondaryMain} ${imp}`,
              '@media (min-width: 1366px)': {
                textAlign: `center ${imp}`,
              },
            },
          },
        },
        '.register-card': {
          '@media (max-width: 1024px)': {
            width: `auto ${imp}`,
            margin: `0 16px ${imp}`,
          },
          '@media (min-width: 1024px)': {
            width: `766px ${imp}`,
            padding: `40px 90px 50px ${imp}`,
          },
        },
        '.login-card': {
          maxWidth: `436px ${imp}`,
          '@media (min-width: 1024px) and (min-height: 833px)': {
            marginTop: `62px ${imp}`,
            marginBottom: `62px ${imp}`,
          },
          '& .commitment': {
            display: `none ${imp}`,
          },
        },
        '.forgot-card': {
          maxWidth: `565px ${imp}`,
        },
        '.faq-page': {
          paddingTop: `0 ${imp}`,
          paddingBottom: `0 ${imp}`,
          marginBottom: `0 ${imp}`,
          '&__hero': {
            maxWidth: `1000px ${imp}`,
            minHeight: `0 ${imp}`,
            display: `block ${imp}`,
          },
          '&__title': {
            ...title32,
            color: `${green} ${imp}`, //surcouche pour le titre
          },
          '&__cat': {
            '&.MuiTypography-root': {
              fontSize: `14px ${imp}`,
              letterSpacing: `1.45px ${imp}`,
              textAlign: `center ${imp}`,
              paddingTop: `30px ${imp}`,
              marginBottom: `0 ${imp}`,
              color: `${green} ${imp}`,
              '@media (min-width: 1024px)': {
                paddingTop: `50px ${imp}`,
                marginBottom: `0 ${imp}`,
                fontSize: `20px ${imp}`,
                letterSpacing: `2px ${imp}`,
              },
            },
          },
          '&__anchor.MuiButton-root': {
            ...btn2ndHandOutlined,
            fontSize: `10px ${imp}`,
            paddingTop: `5px ${imp}`,
            paddingBottom: `4px ${imp}`,
            '& .MuiButton-label': {
              top: `0 ${imp}`,
            },
          },
        },
        '.faq-menu': {
          '@media (max-width: 1024px) ': {
            marginTop: `10px ${imp}`,
            whiteSpace: `nowrap ${imp}`,
            overflowX: `scroll`,
            scrollPadding: `0 50% ${imp}`,
            scrollSnapType: `x mandatory ${imp}`,
            '&>span': {
              scrollSnapAlign: `left ${imp}`,
              display: `inline-block ${imp}`,
              whiteSpace: `nowrap ${imp}`,
              '&>button': {
                margin: `0 16px 0 0 ${imp}`,
              },
            },
          },
        },
        '.faq-content': {
          maxWidth: `1035px ${imp}`,
          '@media (min-width: 1024px)': {
            paddingLeft: `0 ${imp}`,
            paddingRight: `0 ${imp}`,
          },
          '@media (max-width: 1024px) ': {
            paddingTop: `0 ${imp}`,
          },
        },
        '.link-card': {
          color: black,
          '@media (min-width: 1024px)': {
            margin: 25,
          },
          '&__title': {
            ...title14,
            textTransform: `none ${imp}`,
            '&>*': {
              ...title14,
              textTransform: `none ${imp}`,
            },
          },
        },
        '.contact-page, .confirmation': {
          '@media (max-width: 1024px)': {
            padding: `0 ${imp}`,
          },
        },
        '.account-informations, .account__box': {
          '& .MuiButton-outlinedPrimary': {
            ...btn2ndHand,
          },
          '&__title': {
            color: `${black} ${imp}`,
            '& .MuiSvgIcon-root': {
              display: `none ${imp}`,
            },
          },
          '&>.MuiGrid-root': {
            margin: -10,
            '&>.MuiGrid-item': {
              padding: 10,
            },
          },
        },
        '.page': {
          height: `auto ${imp}`,
          minHeight: `calc("100vh - 77px") ${imp}`,
          background: `${white} ${imp}`,
          '@media (max-width: 1024px)': {
            minHeight: `calc(100vh - ${headerHeightMobile}) ${imp}`,
          },
          '&--home': {
            marginTop: `0 ${imp}`,
            '& .footer__top': {
              display: `block ${imp}`,
            },
          },
          '&--cms': {
            marginTop: `0 ${imp}`,
            '& .header--top': {
              background: `transparent ${imp}`,
              '& .menu': {
                '&__link': {},
              },
              '& .header': {
                '&__icons': {},
                '&__cta': {
                  '&--account': {},
                  '&--faq': {},
                },
                '&__burger': {
                  // ...iconMenuBurgerWhite,
                },
              },
              '& #menu-toggler': {
                // color: `${white} ${imp}`,
                //...iconCartWhite,
              },
            },
            '& .page-content': {
              '& .hero_content': {
                '& >h1': {
                  color: `${white} ${imp}`,
                },
              },
            },
            '& .ctas': {
              '& .MuiButton-root': {
                color: `${white} ${imp}`,
                background: `transparent`,
              },
            },
          },
          '&__hero': {
            color: `${secondaryMain} ${imp}`,
            background: `${white} ${imp}`,
            textAlign: 'center',
            maxWidth: `100% ${imp}`,
            textTransform: `uppercase ${imp}`,
            padding: `80px 0 35px ${imp}`,
            marginBottom: `35px ${imp}`,
            '& .MuiTypography-root': {
              letterSpacing: `3px ${imp}`,
              fontWeight: `700 ${imp}`,
            },
            '@media (min-width: 1024px)': {
              paddingBottom: `75px ${imp}`,
              marginBottom: `55px ${imp}`,
            },
          },
          '& .hero_content': {
            gap: `15px ${imp}`,
          },
          '&-content': {
            minHeight: '600px !important',
            '& #section-hero_section-0': {
              paddingBottom: `30px ${imp}`,
              '& .section_container': {
                alignItems: 'flex-end',
                paddingBottom: `29px ${imp}`,
                minHeight: `515px ${imp}`,
                '@media (min-width: 1024px)': {
                  minHeight: `637px ${imp}`,
                },
                '& .hero_content': {
                  gap: `25px ${imp}`,
                },
                '& .MuiTypography-h1': {
                  fontSize: '18px',
                  letterSpacing: '.64px',
                  maxWidth: `70% ${imp}`,
                  margin: 'auto',
                  '@media (min-width: 1024px)': {
                    maxWidth: `40% ${imp}`,
                    fontSize: '31px',
                    letterSpacing: '1.11px',
                  },
                },
              },
              '& .ctas': {
                '@media (max-width: 1024px)': {
                  '& .MuiButton-root': {
                    minWidth: `170px ${imp}`,
                  },
                },
                '@media (min-width: 1366px)': {
                  bottom: `25px ${imp}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: `98px ${imp}`,
                },
                '@media (min-width: 1920px)': {
                  bottom: `70px ${imp}`,
                },
                '@media (max-width: 1024px) and (max-height: 667px)': {
                  bottom: `10px ${imp}`,
                },
              },
            },
            '@media (max-width: 1024px)': {
              paddingTop: 0,
              '& section:first-child': {
                '& #majelocation': {
                  ...titleWithLogo,
                },
              },
              '& section:nth-child(n+2)': {
                '& .page-wysiwyg > img': {
                  maxWidth: `none ${imp}`,
                  width: '100vw',
                  position: 'relative',
                  transform: 'translateX(-50%) translateY(0)',
                  left: '50%',
                },
              },
            },
            '& #section-text-1': {
              paddingBottom: `0 ${imp}`,
            },
            '& #section-columns-1': {
              '@media (min-width: 1440px)': {
                paddingTop: 70,
              },
            },
            '& #section-columns-1 #column-2': {
              '@media (min-width: 1024px)': {
                paddingTop: 148,
              },
            },
            '& #section-columns-2': {
              padding: `12px ${imp}`,
              '& .page__container--columns': {
                padding: `0 ${imp}`,
                '@media (max-width: 1024px)': {
                  maxWidth: `100% ${imp}`,
                },
              },
              '& .page-wysiwyg': {
                '& p': {
                  fontSize: `11px ${imp}`,
                },
              },
              '& .MuiGrid-grid-xs-12': {
                flexBasis: '50%',
                maxWidth: '50%',
              },
            },
            '& #section-columns-3 .MuiGrid-container #column-1, & #section-columns-4 .MuiGrid-container #column-0':
              {
                '@media (max-width: 768px)': {
                  padding: 20,
                },
              },
            '& #section-columns-4 .MuiGrid-container': {
              '@media (max-width: 768px)': {
                flexDirection: 'column-reverse',
              },
            },
          },
          '&__container': {
            '@media (max-width: 768px)': {
              maxWidth: 327,
            },
            '@media (min-width: 1024px)': {
              maxWidth: 1142,
            },
            '&--columns': {
              '@media (min-width: 1024px)': {
                maxWidth: `1142px ${imp}`,
                '&.MuiContainer-maxWidthXl': {
                  maxWidth: `1440px ${imp}`,
                  padding: `0 ${imp}`,
                },
              },
              '& .MuiGrid-spacing-xs-3': {
                '@media (min-width: 1024px)': {
                  margin: -25,
                  width: 'calc(100% + 50px)',
                  '&>.MuiGrid-item': {
                    padding: 25,
                  },
                },
              },
              '& .page-wysiwyg': {
                '& *': {
                  marginBottom: `15px ${imp}`,
                },
                '& p': {
                  lineHeight: 1.58,
                  letterSpacing: 'normal',

                  '& strong': {
                    color: black,
                    fontWeight: 600,
                  },
                },
                '& .block-img, & img': {
                  marginBottom: `0 ${imp}`,
                },
              },
              '&--fullscreen': {
                '& img': {
                  width: `100% ${imp}`,
                },
                '& p:not(.block-img), & ul': {
                  maxWidth: 309,
                  margin: 'auto',
                },
                '& h2': {
                  ...title18,
                  '&>strong': {
                    ...title18,
                  },
                },
                '& .page-wysiwyg': {
                  '& ul': {
                    marginBottom: `42px ${imp}`,
                  },
                },
                '@media (max-width: 768px)': {
                  maxWidth: `100% ${imp}`,
                  padding: `0 ${imp}`,
                },
              },
            },
          },
          '&-wysiwyg': {
            '& > *': {
              lineHeight: 2.4,
              marginBottom: `55px ${imp}`,
            },
            '& .block-img': {
              marginBottom: `0 ${imp}`,
            },
            '& img': {
              maxWidth: 650,
              marginBottom: `0 ${imp}`,
            },
            '& b, & h1, & h2, & h3': {
              color: `${secondaryMain} ${imp}`,
              letterSpacing: `.64px ${imp}`,
            },
            '& h2, & h1': {
              ...title18,
              lineHeight: `normal ${imp}`,
              fontWeight: `${500} ${imp}`,
              '&>strong, & a': {
                fontWeight: `${600} ${imp}`,
                color: `${secondaryMain} ${imp}`,
              },
            },
            '& h1': {
              marginBottom: `24px ${imp}`,
            },
            '& h2': {
              '&>strong': {
                fontSize: `30px ${imp}`,
                lineHeight: `normal ${imp}`,
                letterSpacing: `1.07px ${imp}`,
              },
            },
            '& ul': {
              ...listStyle,
            },
            '& em': {
              fontSize: 9,
              fontStyle: 'normal',
            },
          },
          '&-carousel': {
            '&__title': {
              ...title14,
              marginBottom: `18px ${imp}`,
              '@media (min-width: 1024px)': {
                marginLeft: `48px ${imp}`,
              },
            },
            '&-slide': {
              '&__content': {
                position: `static ${imp}`,
                padding: `16px 0 ${imp}`,
                background: `none ${imp}`,
                color: `${black} ${imp}`,
                textAlign: `left ${imp}`,
                ...title11,
                '& .MuiTypography-root': {
                  color: `${black} ${imp}`,
                },
              },
            },
          },
          '&--product': {
            '& .product__prices': {
              display: `flex ${imp}`,
              alignItems: `center ${imp}`,
              '& .product__price--start': {
                paddingRight: `6px ${imp}`,
              },
              '& .product__price--original': {
                paddingLeft: `6px ${imp}`,
                borderLeft: `1px solid ${black}`,
              },
            },
          },
        },
        '.page__hero--YMiuKBEAACIAmHHw': {
          '@media (max-width: 1024px)': {
            display: `block ${imp}`,
            paddingBottom: `50px ${imp}`,
            marginBottom: `0 ${imp}`,
            '& .MuiBox-root': {
              paddingTop: `25px ${imp}`,
            },
            '& .MuiTypography-root': {
              fontSize: `27px ${imp}`,
              letterSpacing: `2.7px ${imp}`,
              '&:before': {
                display: `none ${imp}`,
              },
            },
          },
        },
        '.page__content--Yz2l4hEAACEAg4al': {
          '& #section-hero_section-0': {
            '& .section_container': {
              '@media (max-width: 750px)': {
                minHeight: `250px ${imp}`,
              },
              '@media (min-width: 1024px)': {
                minHeight: `395px ${imp}`,
              },
            },
          },
          '& #section-columns-1': {
            '@media (min-width: 1024px)': {
              paddingTop: 55,
            },
            '& .page__container--columns': {
              maxWidth: `1200px ${imp}`,
              '& .MuiGrid-container': {
                alignItems: `center ${imp}`,
                '@media (max-width: 767px)': {},
              },
              '@media (max-width: 1024px)': {},
            },
          },

          '& .page-wysiwyg': {
            '& h2': {
              marginBottom: `30px ${imp}`,
              '&:not(:first-of-type)': {
                marginTop: `50px ${imp}`,
              },
            },
            '& p': {
              lineHeight: `1.67 ${imp}`,
              letterSpacing: `0.36px ${imp}`,
            },
          },
        },
        '.page__content--YMiuKBEAACIAmHHw': {
          '@media (max-width: 1024px)': {
            paddingTop: `32px ${imp}`,
          },
          '& .page__container--widget': {
            marginBottom: 68,
            '@media (max-width: 1024px)': {
              marginBottom: 45,
            },
          },
          '& .page__container--columns': {
            maxWidth: `1200px ${imp}`,
            marginTop: -45,
            '& .MuiGrid-container': {
              alignItems: `center ${imp}`,
            },
            '@media (max-width: 1024px)': {
              marginTop: -32,
            },
          },
          '& .page__container--carousel': {
            maxWidth: 1400,
            marginTop: 82,
            '@media (max-width: 1024px)': {
              marginTop: 45,
            },
          },
          '& .page-wysiwyg': {
            '& > *': {
              lineHeight: 1.6,
              marginBottom: `12px ${imp}`,
            },
            '& b, & strong, & h1, & h2, & h3': {
              color: black,
            },
            '& h1': {
              '@media (min-width: 1024px)': {
                fontSize: `34px ${imp}`,
                letterSpacing: `3.4px ${imp}`,
                marginBottom: `28px ${imp}`,
              },
            },
            '& h2': {
              fontWeight: `400 ${imp}`,
              '& strong': {
                fontWeight: `700 ${imp}`,
              },
              '@media (min-width: 1024px)': {
                fontSize: `20px ${imp}`,
                letterSpacing: `2px ${imp}`,
                marginBottom: `28px ${imp}`,
              },
            },
            '& em': {
              fontSize: 9,
              fontStyle: 'normal',
            },
          },
        },
        '.tryout__btn': {
          color: `black ${imp}`,
          border: `black 1px solid ${imp}`,
          padding: `12px ${imp}`,
        },
        '.spck': {
          '&-list': {
            '.page &': {
              '& *': {
                fontFamily: `'AvtGarde', sans-serif ${imp}`,
              },
            },
          },
          '&-shop-info': {
            '.page &': {
              paddingTop: `34px ${imp}`,
            },
          },
          '&-watch-button': {
            '.page &': {
              borderRadius: `0 ${imp}`,
              textTransform: `uppercase ${imp}`,
              height: `45px ${imp}`,
            },
          },
          '&-button-paragraph': {
            '.page &': {
              fontSize: `12px ${imp}`,
              letterSpacing: `.9px ${imp}`,
              fontWeight: `600 ${imp}`,
            },
          },
          '&-event-date': {
            '.page &': {
              fontSize: `11px ${imp}`,
              fontWeight: `700 ${imp}`,
            },
          },
          '&-event-title': {
            '.page &': {
              ...title18,
              textTransform: `uppercase ${imp}`,
              letterSpacing: `2.5px ${imp}`,
              fontWeight: `700 ${imp}`,
              marginTop: `10px ${imp}`,
            },
          },
          '&-event-description': {
            '.page &': {
              marginTop: `10px ${imp}`,
              textAlign: `left ${imp}`,
            },
          },
        },
        '.modal': {
          '&>div[aria-hidden=true]': {
            background: `rgba(0, 0, 0, 0.37) ${imp}`,
          },
          '&__close': {
            width: `64px ${imp}`,
            height: `64px ${imp}`,
            top: `0 ${imp}`,
            right: `0 ${imp}`,
            borderRadius: `0 ${imp}`,
            color: `${white} ${imp}`,
            background: `rgba(0, 0, 0, 0.48) ${imp}`,
          },
          '&__container': {
            maxWidth: `1112px ${imp}`,
            outline: `none${imp}`,
          },
          '&__content': {
            padding: `0${imp}`,
          },
        },
        '.search__button__icon': {
          borderRadius: '16px',
          height: '20px',
          textTransform: `capitalize`,
          fontSize: `10px${imp}`,
          '& span span:first-child': {
            marginRight: '20px',
          },
        },
        '.search__button__icon__only': {
          color: '#000',
          fontSize: `1rem${imp}`,
        },
        '.search__button__icon__only__transparent': {
          color: '#FFF',
        },
        '.search_input_container': {
          borderRadius: '18px',
        },
        '.search_input_icon_button': {
          borderRadius: '14px!important',
        },
        '.backInStockAlert__button': {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: '8px 32px',
        '&:hover, &:focus': {},
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        fontSize: 12,
        padding: '14px 32px',
      },
      label: {
        position: 'relative',
        top: 2,
        '& .MuiSvgIcon-root': {
          position: 'relative',
          top: -2,
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus': {
          boxShadow: 'none',
        },
        '&[disabled]': {
          background: `${grey} ${imp}`,
        },
      },
      containedPrimary: {
        ...btn2ndHand,
      },
      containedSecondary: {
        ...btn2ndHand,
      },
      outlinedPrimary: {},
      textPrimary: {
        color: white,
      },
      textSecondary: {
        color: white,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: `0px 0px 15px rgba(46, 46, 46, 0.1) ${imp}`,
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: `0 0 0 1px #F4F4F4 ${imp}`,
        '&:before': {
          backgroundColor: `${secondaryMain} ${imp}`,
        },
      },
    },
    MuiAccordionSummary: {
      expandIcon: {
        color: `${parme} ${imp}`,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        color: `${white}${imp}`,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: '#000',
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: '#B5DCCD', //Paul 8 avril, ancienne couleur color: '#2933E7',
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: '#E54C80',
        backgroundColor: 'transparent',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: 11,
        letterSpacing: '1.1px',
      },
    },
    MuiCircularProgress: {
      svg: {
        color: secondaryMain,
      },
    },
  },
})

export default theme
